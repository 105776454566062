import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/book-a-speaker-section1";
import Section2 from '../sections/book-a-speaker-section2';
import Section3 from '../sections/book-a-speaker-section3';
import Section4 from "../sections/womenbiz-for-leaders-section4";
import SEO from "../components/seo"

export default class BookASpeaker extends React.Component {

  componentDidMount() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = `
    var anchor1 = document.getElementById('BookaSpeaker-BookUsNow-Button');
    anchor1.onclick = function() {
            fbq('track', 'Book a Speaker', {page: "Book a Speaker", action: "BookaSpeaker-BookUsNow-Button"});
    };
    var anchor2 = document.getElementById('BookaSpeaker-GettoKnowOurMentors-Button');
    anchor2.onclick = function() {
             fbq('track', 'Book a Speaker', {page: "Book a Speaker", action: "BookaSpeaker-GettoKnowOurMentors-Button"});
    };
    var anchor3 = document.getElementById('Womenbiz4-GetConnected-Button');
    anchor3.onclick = function() {
            fbq('track', 'Book a Speaker', {page: "Book a Speaker", action: "Womenbiz4-GetConnected-Button"});
    };
    var anchor4 = document.getElementById('Womenbiz4-DownloadOurPrograms-Button');
    anchor4.onclick = function() {
            fbq('track', 'Book a Speaker', {page: "Book a Speaker", action: "Womenbiz4-DownloadOurPrograms-Button"});
    };
    `
    ;
    document.body.appendChild(s);
  }

  render(){
  return (
    <Layout
      LogoColor={props => props.theme.colors.secondary_green} 
      HeaderMenuColor={props => props.theme.colors.gray_dark} 
      firstSectionBGColor={props => props.theme.colors.white}

      //button
      HeaderButtonColor={props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.primary_small_button_yellow_gray.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.primary_small_button_yellow_gray.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color}
    >

      <SEO title={"Book A Speaker"} description={"We help you build gender-equal talent development strategy for your company"} />

      <Section1/>
      <Section2/>
      <Section3/>
      <Section4/>
    </Layout>   
  )
}
}
