import React, { useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import styled from "@emotion/styled"
import Container from "react-bootstrap/Container"
import { css } from "@emotion/core"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

const BeAMentorSection2Background = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  min-height: 669px;
  @media (max-width: 767px) {
    min-height: 0;
    padding-top: 50px;
    overflow: hidden;
  }
`

const SectionItem = styled.div`
  margin-bottom: 100px;
  width: 100%;
  display: inline-block;
  @media (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 50px;
  }
`

const BeAMentorSection2Title = styled.div`
  font: 58px/62px Abril Fatface;
  margin-bottom: 20px;
  min-height: 132px;
  @media (max-width: 1199px) {
    font: 56px/60px Abril Fatface;
  }
  @media (max-width: 767px) {
    font: 2.5rem/3rem Abril Fatface;
    margin-bottom: 17.5px;
  }
  @media (min-width: 992px) {
    padding-top: 116px;
  }
  @media (max-width: 991px) {
    padding-top: 50px;
  }
  @media (max-width: 506px) {
    text-align: center;
  }
`

const EventImg = styled.img`
  margin: 17.5px 18px 17.5px 18px;
  width: 210px;
  height: 210px;
  object-fit: contain;
  @media (max-width: 991px) {
    margin: 9px 9px 9px 9px;
  }
  @media (max-width: 1600px) and (min-width:992px){
    width: 300px;
    height: 300px;
  }
  @media (max-width: 506px) {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`

const EnlargedEventImg = styled.img`
  object-fit: contain;
  @media (min-width: 768px) {
    width: calc(550 / 1440 * 100vw);
    max-width: 550px;
    height: auto;
  }
  @media (max-width: 767px) {
    background-color: transparent;
    width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
    height: auto;
  }
`

const EventsImagePathList = [
  "wagely.png",
  "appsflyer.png",
  "lingotalk.png",
  "kataai.png",
  "christin-djuarto.png",
  "stockbit.png",
  "grab.png",
  "pintu.png",
]

function ModalImage(props) {
  return (
    <>
      <Modal
        {...props}
        id={props.eventImagePath}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.open}
        css={css`
          .modal-content {
            width: fit-content;
          }
          .modal-dialog {
            position: relative;
            width: auto;
            pointer-events: none;
          }
          @media (max-width: 767px) {
            .modal-dialog {
              margin: 0;
            }
            .modal-content {
              position: relative;
              display: flex;
              flex-direction: column;
              width: 100%;
              pointer-events: auto;
              background-clip: padding-box;
              border: 0px solidrgba(0, 0, 0, 0);
              border-radius: 0.3rem;
              outline: 0;
              background-color: transparent !important;
            }
            .body{
              background-color: transparent;
            }
          }
        `}
      >
        <EnlargedEventImg alt={props.alt} src={props.src} />
      </Modal>
    </>
  )
}

export default function BookASpeakerSection2(props) {
  const [show, setShow] = useState(false)

  return (
    <BeAMentorSection2Background>
      <Container>
        <SectionItem>
          <Row>
            <Col xs="12" lg="12">
              <BeAMentorSection2Title>Our past events</BeAMentorSection2Title>
            </Col>
          </Row>
          <Row
            css={css`
            justify-content: space-around;
              @media (max-width: 1198px) {
                margin-top: 15px;
              }
              @media (min-width: 1199px) {
                margin-left: 30px;
              }
            `}
          >
            {EventsImagePathList.map(eventImagePath => (
              <Col
                xs="auto"
                css={css`
                  padding: 0px;
                `}
              >
                <Button onClick={() => setShow(eventImagePath)}>
                  <EventImg
                    alt={"WomenWorks event - " + eventImagePath.split(".")[0]}
                    src={"/speaking-events/" + eventImagePath}
                  />
                </Button>
                <Button onClick={() => setShow(false)} role="button">
                  <ModalImage
                    id={eventImagePath}
                    open={show === eventImagePath}
                    alt={"WomenWorks event - " + eventImagePath.split(".")[0]}
                    src={"/speaking-events/" + eventImagePath}
                  />
                </Button>
              </Col>
            ))}
          </Row>
        </SectionItem>
      </Container>
    </BeAMentorSection2Background>
  )
}
